import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../apiInstances/baseurl";
import { AgencyLogo } from "../../assets";
import { HeaderData, HeaderDataAgent } from "../../Constants";

const SideBar = ({ AgencyProfile }) => {
  const getPath = useLocation().pathname.split("/")?.[1];
  const role = localStorage.getItem("role");
  const selectRole = useMemo(() => {
    return role === "agent" ? HeaderDataAgent : HeaderData;
  }, [role]);
  return (
    <>
      <ul className="h-full bg-white rounded-lg shadow-md hover:shadow-lg px-6">
        <li className="grid place-content-center">
          {AgencyProfile.agencySmallLogo ? (
            <img
              src={`${BACKEND_BASE_URL}${AgencyProfile?.agencySmallLogo}`}
              alt=""
              className="grid place-content-center rounded-xl my-6 h-32"
            />
          ) : AgencyProfile[0]?.agencyDetails[0]?.agencySmallLogo ? (
            <img
              src={`${BACKEND_BASE_URL}${AgencyProfile[0]?.agencyDetails[0]?.agencySmallLogo}`}
              alt=""
              className="grid place-content-center rounded-xl my-6 h-32"
            />
          ) : (
            <img
              src={AgencyLogo}
              alt=""
              className="grid place-content-center rounded-xl my-6 h-32 w-80"
            />
          )}
        </li>
        {selectRole?.length > 0 &&
          selectRole?.map((data, index) => (
            <div key={index}>
              {`/${getPath}` === data?.pathname ? (
                <li className="min-w-max effect-h my-1">
                  <Link to={data?.pathname}>
                    <div className="flex justify-start items-center gap-3 p-3 font-medium rounded-lg bg-[#E5002A] text-[#FFFFFF]">
                      <img src={data?.activeIcon} alt="" />
                      {data?.pagename}
                    </div>
                  </Link>
                </li>
              ) : (
                <li className="min-w-max my-1">
                  <Link to={data?.pathname}>
                    <div className="flex justify-start items-center gap-3 p-3 font-medium rounded-lg  bg-[#FFFFFF] text-[#525252] hover:bg-[#E5002A] hover:text-[#FFFFFF]">
                      <img src={data?.icon} alt="icon" />
                      {data?.pagename}
                    </div>
                  </Link>
                </li>
              )}
            </div>
          ))}
      </ul>
    </>
  );
};

export default SideBar;
