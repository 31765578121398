import React from "react";
import Layout1 from "../../Layouts/Layout1";
import { useNavigate } from "react-router-dom";
import { DashboardCard } from "../../Constants";

const UserDashBoard = () => {
  const navigate = useNavigate();

  return (
    <Layout1>
      <div className="p-5 xl:px-16">
        <div className="container mx-auto">
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="text-black font-bold text-xl md:text-2xl lg:text-3xl">
              Welcome back,
            </div>
            <div className="text-black font-extrabold text-2xl md:text-3xl lg:text-5xl">
              how can we help you today?
            </div>
          </div>

          {/* <<----- Dashboard Cards ----->> */}
          <div className="grid place-items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-5 md:gap-8 my-6 md:my-16">
            {DashboardCard?.length > 0 &&
              DashboardCard?.map((i, index) => (
                <div
                  key={index}
                  className="h-full w-full flex flex-col justify-center items-center gap-5 bg-white rounded-2xl py-7 md:py-10 shadow-md hover:shadow-lg cursor-pointer"
                  onClick={() => navigate(`${i?.link}`)}
                >
                  <img src={i?.icon} alt="icon" />
                  <p className="font-bold text-[#262626] text-center text-sm md:text-base lg:text-lg mx-5">
                    {i?.title}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout1>
  );
};

export default UserDashBoard;
