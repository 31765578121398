import React, { useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./NavBar.css";
import { AgencyLogo, logo, logoSmall } from "../../assets";
import { HeaderData, HeaderDataAgent } from "../../Constants";
import { BACKEND_BASE_URL } from "../../apiInstances/baseurl";

const NavBar = ({ AgencyProfile }) => {
  const getPath = useLocation().pathname.split("/")?.[1];
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const selectRole = useMemo(() => {
    return role === "agent" ? HeaderDataAgent : HeaderData;
  }, [role]);
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <input type="checkbox" name="" id="" />
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          <ul className="menu-items">
            {/* <li className="grid place-content-center my-3">
              {AgencyProfile?.agencySmallLogo ? (
                <img
                  src={`${BACKEND_BASE_URL}${AgencyProfile[0]?.agencyDetails[0]?.agencySmallLogo}`}
                  alt=""
                  className="w-40 grid place-content-center rounded-xl"
                />
              ) : (
                <img
                  src={AgencyLogo}
                  alt=""
                  className="w-40 grid place-content-center rounded-xl"
                />
              )}
            </li> */}
            <li className="grid place-content-center my-4">
              {AgencyProfile.agencySmallLogo ? (
                <img
                  src={`${BACKEND_BASE_URL}${AgencyProfile?.agencySmallLogo}`}
                  alt=""
                  className="w-40 grid place-content-center rounded-xl"
                />
              ) : AgencyProfile[0]?.agencyDetails[0]?.agencySmallLogo ? (
                <img
                  src={`${BACKEND_BASE_URL}${AgencyProfile[0]?.agencyDetails[0]?.agencySmallLogo}`}
                  alt=""
                  className="w-40 grid place-content-center rounded-xl"
                />
              ) : (
                <img
                  src={AgencyLogo}
                  alt=""
                  className="w-40 grid place-content-center rounded-xl"
                />
              )}
            </li>
            {selectRole?.length > 0 &&
              selectRole?.map((data, index) => (
                <div key={index}>
                  {`/${getPath}` === data?.pathname ? (
                    <li className="min-w-max effect-h mx-6 my-1">
                      <Link to={data.pathname}>
                        <div className="flex justify-start items-center gap-3 p-3 font-medium rounded-lg bg-[#E5002A] text-[#FFFFFF]">
                          <img src={data?.activeIcon} alt="icon" />
                          {data.pagename}
                        </div>
                      </Link>
                    </li>
                  ) : (
                    <li className="mx-6 my-1">
                      <Link to={data?.pathname}>
                        <div className="flex justify-start items-center gap-3 p-3 font-medium rounded-lg  bg-[#FFFFFF] text-[#525252] hover:bg-[#E5002A] hover:text-[#FFFFFF]">
                          <img src={data?.icon} alt="icon" />
                          {data.pagename}
                        </div>
                      </Link>
                    </li>
                  )}
                </div>
              ))}
          </ul>
          <div
            className="logo flex justify-center items-center cursor-pointer px-2 xl:px-20"
            onClick={() => navigate("/")}
          >
            <img
              src={logoSmall}
              alt="logo"
              className="w-36 rounded-md cursor-pointer"
            />
            {/* <h1 className="w-auto md:w-28 bg-[#FFCCD3] rounded-lg text-[#FF444D] font-extrabold text-base md:text-lg px-3 sm:px-5 py-2  cursor-pointer">
              La Rosa
            </h1> */}
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
