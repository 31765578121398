import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ag1 from "../../../assets/ag1.png";
import email from "../../../assets/message_icon.png";
import mailWhite from "../../../assets/mailWhite.png";
import call from "../../../assets/call_icon.png";
import shareRed from "../../../assets/shareRed.png";
import { BACKEND_BASE_URL } from "../../../apiInstances/baseurl";

const AgencyConnectCard = ({ AgencyData }) => {
  return (
    <div className="flex flex-col justify-between bg-white rounded-xl shadow-md hover:shadow-lg cursor-pointer">
      {/* ------ Profile ------ */}
      <div className="flex flex-col justify-center items-center">
        <div
          className={`w-full grid place-items-center py-3 rounded-t-xl bg-[#FFE512]`}
        >
          <LazyLoadImage
            src={`${BACKEND_BASE_URL}${AgencyData?.agencySmallLogo}`}
            alt=""
            srcSet={`${BACKEND_BASE_URL}${AgencyData?.agencySmallLogo}`}
            loading="lazy"
            effect="blur"
            className="h-10 rounded-lg"
          />
        </div>
        <div className="flex flex-col justify-center items-center pt-4 md:pt-6 px-4 md:px-6">
          <div className="text-[#171717] text-center font-semibold text-base md:text-lg lg:text-xl">
            {AgencyData?.principal_name}
          </div>

          {/* ------ Ratings ------ */}
          <div className="flex justify-center items-center mt-4">
            <svg
              aria-hidden="true"
              className="w-5 h-5 hover:text-gray-300 text-yellow-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>First star</title>
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              aria-hidden="true"
              className="w-5 h-5 hover:text-gray-300 text-yellow-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Second star</title>
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              aria-hidden="true"
              className="w-5 h-5 hover:text-gray-300 text-yellow-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Third star</title>
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              aria-hidden="true"
              className="w-5 h-5 hover:text-gray-300 text-yellow-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Fourth star</title>
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
            <svg
              aria-hidden="true"
              className="w-5 h-5 hover:text-gray-300 text-yellow-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Fifth star</title>
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
            </svg>
          </div>
          <div className="text-[#A3A3A3] text-center font-medium text-xs mt-1">
            5.0 (482 review)
          </div>
        </div>
      </div>
      {/* ------ Buttons ------ */}
      <div className="w-full p-4 md:p-6">
        <div className="flex flex-col justify-end items-end gap-3">
          <div className="w-full flex justify-center items-center gap-4">
            <button className="w-full flex justify-center items-center gap-2 text-xs font-medium border text-[#FFFFFF] bg-[#E5002A] border-[#E5002A] py-3 px-5 rounded-3xl">
              <img src={mailWhite} alt="icon" className="w-4 cursor-pointer" />
              <div>Request a free appraisal</div>
            </button>
            <div className="grid place-content-center bg-[#FFEAEF] border border-[#FA979A] rounded-full p-2 md:p-3 cursor-pointer">
              <LazyLoadImage
                src={call}
                alt="icon"
                srcSet={call}
                loading="lazy"
                effect="blur"
                className="w-4 md:w-5"
              />
            </div>
          </div>
          <div className="w-full flex justify-center items-center gap-4">
            <button className="w-full flex justify-center items-center gap-2 text-xs font-medium border text-[#737373] bg-[#FFFFFF] border-[#737373] py-3 px-5 rounded-3xl">
              <img src={email} alt="icon" className="w-4 cursor-pointer" />
              <div>Enquire</div>
            </button>
            <div className="grid place-content-center bg-[#FFEAEF] border border-[#FA979A] rounded-full p-2 md:p-3 cursor-pointer">
              <LazyLoadImage
                src={shareRed}
                alt="icon"
                srcSet={shareRed}
                loading="lazy"
                effect="blur"
                className="w-4 md:w-5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyConnectCard;
