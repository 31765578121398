import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstanceAuth from "../../apiInstances/axiosInstanceAuth";
import axiosInstanceAuthFormData from "../../apiInstances/axiosInstanceAuthFormData";
import { BACKEND_BASE_URL } from "../../apiInstances/baseurl";
import {
  agencyLargeLogo,
  AgencyLogo,
  agencyMediumLogo,
  agencySmallLogo,
  heroImg,
  questionMark,
} from "../../assets";
import { TextColor } from "../../Constants";
import Layout1 from "../../Layouts/Layout1";
import { ColourPicker } from "./ColourPicker";

const Agencybranding = () => {
  const COLOR = "red";
  const [color, setColor] = useState(COLOR);
  const navigate = useNavigate();
  const Role = localStorage.getItem("role");
  useEffect(() => {
    GetAgencyBranding();
  }, []);

  const GetAgencyBranding = async () => {
    await axiosInstanceAuth
      .post(`agency/ViewProfile`, { role: Role })
      .then((res) => {
        const mydata = res?.data?.data;
        if (res?.data?.status) {
          setAgentImages({
            ...AgentImages,
            agencySmallLogo: mydata?.agencySmallLogo,
            agencyMediumLogo: mydata?.agencyMediumLogo,
            agencyLargeLogo: mydata?.agencyLargeLogo,
            heroImg: mydata?.heroImg,
          });

          setAgencyBranding({
            ...AgencyBranding,
            text_color: mydata?.text_color,
            primary_color: color,
            secondary_color: mydata?.secondary_color,
          });
        } else {
          // toast.error("Oops! Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  const EditAgencyBranding = async () => {
    try {
      if (AgentImages?.agencySmallLogo === null) {
        toast.error("Please upload Agency Small Logo");
      } else if (AgentImages?.agencyMediumLogo === null) {
        toast.error("Please upload Agency Medium Logo");
      } else if (AgentImages?.agencyLargeLogo === null) {
        toast.error("Please upload Agency Large Logo");
      } else if (AgentImages?.heroImg === null) {
        toast.error("Please upload Hero Imgage");
      } else if (AgentImages?.agencyMediumLogo === null) {
        toast.error("Please upload Agency Medium Logo");
      } else if (AgentImages?.agencyMediumLogo === null) {
        toast.error("Please upload Agency Medium Logo");
      } else {
        const formData = new FormData();
        formData.append("text_color", AgencyBranding?.text_color);
        formData.append("primary_color", color);
        formData.append("secondary_color", color);
        formData.append("agencySmallLogo", AgentImages?.agencySmallLogo);
        formData.append("agencyMediumLogo", AgentImages?.agencyMediumLogo);
        formData.append("agencyLargeLogo", AgentImages?.agencyLargeLogo);

        formData.append("heroImg", AgentImages?.heroImg);

        axiosInstanceAuthFormData
          .post(`agency/branding_Update`, formData)
          .then((res) => {
            if (res?.data?.status) {
              toast.success("Agency Branding Edited Successfuly");
              navigate(`/`);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            console.log("------>> Error", err);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [AgentImages, setAgentImages] = useState({
    agencySmallLogo: null,
    agencySmallLogoShow: null,
    agencyMediumLogo: null,
    agencyMediumLogoShow: null,
    agencyLargeLogo: null,
    agencyLargeLogoShow: null,
    heroImg: null,
    heroImgShow: null,
  });
  console.log(
    "🚀 ~ file: Agencybranding.jsx:108 ~ Agencybranding ~ AgentImages:",
    AgentImages
  );
  const [AgencyBranding, setAgencyBranding] = useState({
    text_color: "black",
    primary_color: "",
    secondary_color: "",
  });
  const onChangeImages = (e) => {
    const { name } = e.target;
    setAgentImages({
      ...AgentImages,
      [name]: e.target.files[0],
      [`${name}Show`]: URL.createObjectURL(e.target.files[0]),
    });

    // let img = new Image();
    // img.src = window.URL.createObjectURL(e.target.files[0]);
    // img.onload = () => {
    //   if (
    //     ([name] == "agencySmallLogo" ||
    //       [name] == "agencyMediumLogo" ||
    //       [name] == "agencyLargeLogo") &&
    //     img.width / img.height < 4
    //   ) {
    //     toast.info(`Please Maintain Minimum 4:1 Aspect Ratio`);
    //   }

    //   if (
    //     ([name] == "commercialAgencySmallLogo" ||
    //       [name] == "commercialAgencyMediumLogo" ||
    //       [name] == "commercialAgencyLargeLogo" ||
    //       [name] == "commercialAgencyExtraLargeLogo") &&
    //     img.width / img.height < 2
    //   ) {
    //     toast.info(`Please Maintain Minimum 2:1 Aspect Ratio`);
    //   }
    // };
  };

  const onChangeAllImages = (e) => {
    const { name } = e.target;

    if (e.target.files?.length < 3) {
      toast.error("Please upload All Logo");
    } else {
      setAgentImages({
        ...AgentImages,
        agencySmallLogo: e.target.files[0],
        agencySmallLogoShow: URL.createObjectURL(e.target.files[0]),
        agencyMediumLogo: e.target.files[1],
        agencyMediumLogoShow: URL.createObjectURL(e.target.files[1]),
        agencyLargeLogo: e.target.files[2],
        agencyLargeLogoShow: URL.createObjectURL(e.target.files[2]),
      });
    }
  };

  // const onChangeAllImages = (e) => {
  //   const { name } = e.target;

  //   if (e.target.files?.length < 1 || e.target.files?.length > 3) {
  //     toast.error("Please upload 1 to 3 images");
  //   } else {
  //     const newImages = {};

  //     if (e.target.files[0]) {
  //       const img = new Image();
  //       console.log("🚀 ~ onChangeAllImages ~ img:", img.src);
  //       img.src = URL.createObjectURL(e.target.files[0]);
  //       img.onload = () => {
  //         if (img.width !== 510 || img.height !== 96) {
  //           toast.error(
  //             "Please upload 1st image with dimensions of 510px by 96px."
  //           );
  //         } else {
  //           setAgentImages({
  //             ...AgentImages,
  //             agencySmallLogo: e.target.files[0],
  //             agencySmallLogoShow: URL.createObjectURL(e.target.files[0]),
  //           });
  //         }
  //       };
  //     }

  //     if (e.target.files[1]) {
  //       const img = new Image();
  //       img.src = URL.createObjectURL(e.target.files[1]);
  //       img.onload = () => {
  //         if (img.width !== 510 || img.height !== 96) {
  //           toast.error(
  //             "Please upload 2nd image with dimensions of 510px by 96px."
  //           );
  //         } else {
  //           setAgentImages({
  //             ...AgentImages,
  //             agencySmallLogo: e.target.files[1],
  //             agencySmallLogoShow: URL.createObjectURL(e.target.files[1]),
  //           });
  //         }
  //       };
  //     }

  //     if (e.target.files[2]) {
  //       const img = new Image();
  //       img.src = URL.createObjectURL(e.target.files[2]);
  //       img.onload = () => {
  //         if (img.width !== 510 || img.height !== 96) {
  //           toast.error(
  //             "Please upload 3rd image with dimensions of 510px by 96px."
  //           );
  //         } else {
  //           setAgentImages({
  //             ...AgentImages,
  //             agencySmallLogo: e.target.files[2],
  //             agencySmallLogoShow: URL.createObjectURL(e.target.files[2]),
  //           });
  //         }
  //       };
  //     }

  //     // setAgentImages({
  //     //   ...AgentImages,
  //     //   ...newImages,
  //     // });
  //   }
  // };

  // const onChangeAllCommercialImages = (e) => {
  //   const { name } = e.target;

  //   if (e.target.files?.length < 4) {
  //     toast.error("Please upload All Commercial Logo");
  //   } else {
  //     setAgentImages({
  //       ...AgentImages,
  //       commercialAgencySmallLogo: e.target.files[0],
  //       commercialAgencySmallLogoShow: URL.createObjectURL(e.target.files[0]),
  //       commercialAgencyMediumLogo: e.target.files[1],
  //       commercialAgencyMediumLogoShow: URL.createObjectURL(e.target.files[1]),
  //       commercialAgencyLargeLogo: e.target.files[2],
  //       commercialAgencyLargeLogoShow: URL.createObjectURL(e.target.files[2]),
  //       commercialAgencyExtraLargeLogo: e.target.files[3],
  //       commercialAgencyExtraLargeLogoShow: URL.createObjectURL(
  //         e.target.files[3]
  //       ),
  //     });
  //   }
  // };

  const onDeleteImages = (e) => {
    setAgentImages({
      ...AgentImages,
      [e]: null,
      [`${e}Show`]: null,
    });
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setAgencyBranding({ ...AgencyBranding, [name]: value });
  };

  return (
    <Layout1>
      <div className="container mx-auto px-5 xl:px-0">
        {/* ---------- section 1  ---------- */}
        <div className="w-full flex flex-col justify-start bg-white rounded-2xl shadow-md text-[#404040] font-medium p-6 mb-10">
          <div
            className="font-semibold text-[#3B8FD4] text-sm md:text-base cursor-pointer"
            onClick={() => navigate(`/agency`)}
          >
            View Profile
          </div>

          {/* ----------- Upload agency logo ---------- */}

          <div className="my-2">
            <div className="font-bold text-base md:text-xl lg:text-2xl my-3 md:my-5">
              Upload agency logo
            </div>
            <div className="w-full border border-[#E5E5E5]" />

            <div className="font-medium text-xs md:text-sm lg:text-base my-3 md:my-5">
              <div>
                Upload the agency logo that you would like to appear on the
                website. Logos must meet our
                <span className="px-2 text-[#3B8FD4] cursor-pointer">
                  Acceptable use policy.
                </span>
              </div>
              <div>
                <span className="font-semibold text-[#262626]">JPG, GIF</span>
                {` `}or{` `}
                <span className="font-semibold text-[#262626]">PNG</span>
                {` `} formats only.
              </div>
            </div>

            <div className="border border-[#262626] rounded-lg p-6">
              <div className="flex justify-start items-end gap-2 text-xs md:text-sm lg:text-base text-[#525252]">
                We will resize your logo to the required sizes (For best results
                upload as 510px by 96px).
                <img
                  src={questionMark}
                  alt="icon"
                  className="w-4 md:w-5 mx-1 cursor-pointer"
                />
              </div>

              <div className="w-full flex justify-center md:justify-start items-center  text-white text-xs md:text-sm my-4 md:my-6">
                <label
                  htmlFor="allLogos"
                  className="cursor-pointer py-2 px-5 bg-[#E5002A] rounded-3xl"
                  onClick={() => {}}
                >
                  Upload and replace all logos
                  <input
                    id="allLogos"
                    type="file"
                    multiple
                    name="allLogos"
                    onChange={onChangeAllImages}
                  />
                </label>
              </div>

              <div className="flex flex-col md:flex-row justify-center items-center md:items-end gap-5">
                {/* ----- Small Logo ----- */}
                <div className="flex flex-col justify-center items-center gap-4">
                  {AgentImages?.agencySmallLogoShow ? (
                    <img
                      src={AgentImages?.agencySmallLogoShow}
                      alt="profile"
                      className="border-2 border-dashed border-black rounded-lg w-52 h-28"
                    />
                  ) : AgentImages?.agencySmallLogo ? (
                    <img
                      src={`${BACKEND_BASE_URL}${AgentImages?.agencySmallLogo}`}
                      alt=""
                      className="border-2 border-dashed border-black rounded-2xl w-52 h-28"
                    />
                  ) : (
                    <img
                      src={AgencyLogo}
                      alt=""
                      className="border-2 border-dashed border-black rounded-2xl w-52 h-28 aspect-square"
                    />
                  )}

                  <div className="w-full text-center text-[#3B8FD4] font-medium text-sm md:text-base">
                    <label
                      htmlFor="agencySmallLogo"
                      className="px-2 cursor-pointer"
                      onClick={() => {}}
                    >
                      Replace
                      <input
                        id="agencySmallLogo"
                        type="file"
                        name="agencySmallLogo"
                        onChange={onChangeImages}
                      />
                    </label>
                    |
                    <span
                      className="px-2 cursor-pointer"
                      onClick={(e) => onDeleteImages(`agencySmallLogo`)}
                    >
                      Delete
                    </span>
                  </div>
                </div>

                {/* ----- Medium Logo ----- */}

                <div className="flex flex-col justify-center items-center gap-4">
                  {AgentImages?.agencyMediumLogoShow ? (
                    <img
                      src={AgentImages?.agencyMediumLogoShow}
                      alt="profile"
                      className="border-2 border-dashed border-black rounded-lg w-64 h-36"
                    />
                  ) : AgentImages?.agencyMediumLogo ? (
                    <img
                      src={`${BACKEND_BASE_URL}${AgentImages?.agencyMediumLogo}`}
                      alt=""
                      className="border-2 border-dashed border-black rounded-2xl w-64 h-36"
                    />
                  ) : (
                    <img
                      src={AgencyLogo}
                      alt=""
                      className="border-2 border-dashed border-black rounded-2xl w-64 h-36"
                    />
                  )}

                  <div className="w-full text-center text-[#3B8FD4] font-medium text-sm md:text-base">
                    <label
                      htmlFor="agencyMediumLogo"
                      className="px-2 cursor-pointer"
                      onClick={() => {}}
                    >
                      Replace
                      <input
                        id="agencyMediumLogo"
                        type="file"
                        name="agencyMediumLogo"
                        onChange={onChangeImages}
                      />
                    </label>
                    |
                    <span
                      className="px-2 cursor-pointer"
                      onClick={(e) => onDeleteImages(`agencyMediumLogo`)}
                    >
                      Delete
                    </span>
                  </div>
                </div>

                {/* ----- Large Logo ----- */}

                <div className="flex flex-col justify-center items-center gap-4">
                  {AgentImages?.agencyLargeLogoShow ? (
                    <img
                      src={AgentImages?.agencyLargeLogoShow}
                      alt="profile"
                      className="border-2 border-dashed border-black rounded-lg w-72 h-44"
                    />
                  ) : AgentImages?.agencyLargeLogo ? (
                    <img
                      src={`${BACKEND_BASE_URL}${AgentImages?.agencyLargeLogo}`}
                      alt=""
                      className="border-2 border-dashed border-black rounded-2xl w-72 h-44"
                    />
                  ) : (
                    <img
                      src={AgencyLogo}
                      alt=""
                      className="border-2 border-dashed border-black rounded-2xl w-72 h-44"
                    />
                  )}

                  <div className="w-full text-center text-[#3B8FD4] font-medium text-sm md:text-base">
                    <label
                      htmlFor="agencyLargeLogo"
                      className="px-2 cursor-pointer"
                      onClick={() => {}}
                    >
                      Replace
                      <input
                        id="agencyLargeLogo"
                        type="file"
                        name="agencyLargeLogo"
                        onChange={onChangeImages}
                      />
                    </label>
                    |
                    <span
                      className="px-2 cursor-pointer"
                      onClick={(e) => onDeleteImages(`agencyLargeLogo`)}
                    >
                      Delete
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="font-medium text-xs md:text-sm lg:text-base text-[#404040] py-3 mt-4 md:mt-6">
              Upload your logo for Commercial property
              (www.realcommercial.com.au)
            </div> */}
          </div>

          {/* ----------- Upload Hero Image ---------- */}

          <div className="my-2">
            <div className="font-bold text-base md:text-xl lg:text-2xl my-3 md:my-5">
              Upload Hero Image
            </div>
            <div className="w-full border border-[#E5E5E5]" />

            <div className="font-medium text-xs md:text-sm lg:text-base my-3 md:my-5">
              <div>
                Upload the agency logo that you would like to appear on the
                website. Logos must meet our
                <span className="px-2 text-[#3B8FD4] cursor-pointer">
                  Acceptable use policy.
                </span>
              </div>
              <div>
                <span className="font-semibold text-[#262626]">JPG, GIF</span>
                {` `}or{` `}
                <span className="font-semibold text-[#262626]">PNG</span>
                {` `} formats only.
              </div>
            </div>

            <div className="border border-[#262626] rounded-lg p-6">
              <div className="flex justify-start items-end gap-2 text-xs md:text-sm lg:text-base text-[#525252]">
                We will resize your logo to the required sizes (For best results
                upload as 510px by 96px).
                <img
                  src={questionMark}
                  alt="icon"
                  className="w-4 md:w-5 mx-1 cursor-pointer"
                />
              </div>

              <div className="w-full flex justify-center md:justify-start items-center  text-white text-xs md:text-sm my-4 md:my-6">
                <label
                  htmlFor="heroImg"
                  className="cursor-pointer py-2 px-5 bg-[#E5002A] rounded-3xl"
                  onClick={() => {}}
                >
                  Upload and replace all logos
                  <input
                    id="heroImg"
                    type="file"
                    name="heroImg"
                    onChange={onChangeImages}
                  />
                </label>
              </div>

              <div className="flex flex-col justify-center items-center gap-4 md:gap-6">
                {AgentImages?.heroImgShow ? (
                  <img
                    src={AgentImages?.heroImgShow}
                    alt="profile"
                    className="border-2 border-dashed border-black rounded-lg"
                  />
                ) : AgentImages?.heroImg ? (
                  <img
                    src={`${BACKEND_BASE_URL}${AgentImages?.heroImg}`}
                    alt=""
                    className="border-2 border-dashed border-black rounded-2xl h-full md:h-[50vh] w-full"
                  />
                ) : (
                  <img
                    src={heroImg}
                    alt=""
                    className="border-2 border-dashed border-black rounded-2xl w-96"
                  />
                )}

                <div className="w-full text-center text-[#3B8FD4] font-medium text-sm md:text-base">
                  <label
                    htmlFor="heroImg"
                    className="px-2 cursor-pointer"
                    onClick={() => {}}
                  >
                    Replace
                    <input
                      id="heroImg"
                      type="file"
                      name="heroImg"
                      onChange={onChangeImages}
                    />
                  </label>
                  |
                  <span
                    className="px-2 cursor-pointer"
                    onClick={(e) => onDeleteImages(`heroImg`)}
                  >
                    Delete
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* ----------- Customize colors ---------- */}

          <div className="my-2">
            <div className="font-bold text-base md:text-xl lg:text-2xl my-3 md:my-5">
              Customize colors
            </div>
            <div className="w-full border border-[#E5E5E5]" />

            <div className="flex justify-start items-end gap-2 text-xs md:text-sm lg:text-base text-[#525252] my-3 md:my-5">
              We will resize your logo to the required sizes (For best results
              upload as 510px by 96px).
              <img
                src={questionMark}
                alt="icon"
                className="w-4 md:w-5 mx-1 cursor-pointer"
              />
            </div>

            <div className="border border-[#262626] rounded-lg p-6">
              <div className="font-semibold text-xs md:text-sm lg:text-base py-3">
                Select your logo Backgroun Color
              </div>
              <ColourPicker setColor={setColor} color={color} />
              {/* <div className="flex flex-row justify-start items-center my-4">
                <div className="flex flex-row justify-start items-center border border-[#737373] rounded-3xl px-5">
                  <div className="w-4 h-4 border border-black bg-[#002F4B] rounded-sm"></div>
                  <select
                    name="primary_color"
                    value={AgencyBranding?.primary_color}
                    onChange={onChangeInput}
                    className="!text-[#404040] text-xs md:text-sm lg:text-base outline-none py-2 px-5 cursor-pointer"
                  >
                    <option value="#002F4B">Primary Color</option>
                    <option value="#111111">Black Dark</option>
                    <option value="#222222">Black </option>
                    <option value="#333333">Black Light</option>
                  </select>
                </div>
              </div> */}

              {/* <div className="flex flex-row justify-start items-center my-4">
                <div className="flex flex-row justify-start items-center border border-[#737373] rounded-3xl px-5">
                  <div className="w-4 h-4 border border-black bg-[#FFFFFF] rounded-sm"></div>
                  <select
                    name="secondary_color"
                    value={AgencyBranding?.secondary_color}
                    onChange={onChangeInput}
                    className="!text-[#404040] text-xs md:text-sm lg:text-base outline-none py-2 px-5 cursor-pointer"
                  >
                    <option value="#FFFFFF">Secondary Color</option>
                    <option value="#111111">Black Dark</option>
                    <option value="#222222">Black </option>
                    <option value="#333333">Black Light</option>
                  </select>
                </div>
              </div> */}

              {/* <div className="font-semibold text-xs md:text-sm lg:text-base">
                Select your text colour
              </div> */}

              {/* <div className="flex flex-col justify-start items-start gap-3 my-4">
                {TextColor?.length > 0 &&
                  TextColor?.map((d, index) => (
                    <div
                      key={index}
                      className={`flex justify-center items-center gap-3 border  rounded-3xl font-medium text-xs md:text-sm cursor-pointer py-2 px-5 ${
                        AgencyBranding?.text_color === d?.name &&
                        `text-[#E5002A] bg-[#FFEAEF] border-[#E5002A]`
                      }`}
                      onClick={() => {
                        setAgencyBranding({
                          ...AgencyBranding,
                          text_color: d?.name,
                        });
                      }}
                    >
                      <div className="grid place-content-center rounded-2xl">
                        <input
                          type="checkbox"
                          checked={AgencyBranding?.text_color === d?.name}
                          name={d?.name}
                          className="w-3 h-3 accent-[#E5002A] cursor-pointer"
                        />
                      </div>
                      <div>{d?.title}</div>
                    </div>
                  ))}
              </div> */}
            </div>
          </div>

          <div className="w-full border border-[#E5E5E5] mt-6" />

          {/* ----------- Button ---------- */}

          <div className="w-full flex justify-start items-center  text-white text-xs md:text-sm mt-4 md:mt-6">
            <button
              className="py-2 px-5 bg-[#E5002A] rounded-3xl"
              onClick={EditAgencyBranding}
            >
              Save agency branding
            </button>
          </div>
        </div>
      </div>
    </Layout1>
  );
};

export default Agencybranding;
