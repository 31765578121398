import React, { useEffect, useState } from "react";
import axiosInstanceAuth from "../apiInstances/axiosInstanceAuth";
import { TopHeader, NavBar, SideBar, Footer, Help } from "../components";
import { toast } from "react-toastify";

const Layout1 = ({ children }) => {
  const checkAuth = localStorage.getItem("AgencyToken");
  const Role = localStorage.getItem("role");
  useEffect(() => {
    GetAgencyProfile();
  }, [checkAuth]);

  const [AgencyProfile, setAgencyProfile] = useState({});

  const GetAgencyProfile = async () => {
    await axiosInstanceAuth
      .post(`agency/ViewProfile`, { role: Role })
      .then((res) => {
        const mydata = res?.data?.data;

        if (res?.data?.status) {
          setAgencyProfile(mydata);
          // for (let k in mydata) {
          //   // if (mydata[k] != null) newData[k] = mydata[k];
          //   if (mydata[k] === null)
          //     return toast.error("Oops! Something went wrong");
          // }
        } else {
        }
      })
      .catch((err) => {
        console.log("err --->", err);
      });
  };

  // useEffect(() => {
  //   // Object.keys(AgencyProfile).reduce((acc, el) => {
  //   //   // // removing all the key-value pairs where value=null
  //   //   // if (AgencyProfile[el] !== null) acc[el] = AgencyProfile[el];
  //   //   if (AgencyProfile[el] === null);
  //   //   return toast.error("Oops! Something went wrong");
  //   // }, {});
  // }, [AgencyProfile]);

  return (
    <>
      <TopHeader AgencyProfile={AgencyProfile} />
      <NavBar AgencyProfile={AgencyProfile} />
      <div className="grid grid-cols-10 px-0 lg:px-20 mt-20">
        <div className="hidden xl:block col-span-2">
          <SideBar AgencyProfile={AgencyProfile} />
        </div>
        <div className="col-span-10 xl:col-span-8 mx-0 lg:mx-10">
          <div className="min-h-[640px]">{children}</div>
          <div className="block md:hidden px-5">
            <Help />
          </div>
          <div className="">
            <Footer />
          </div>
        </div>
      </div>
      <div className="hidden md:block m-5">
        <Help />
      </div>
    </>
  );
};

export default Layout1;
